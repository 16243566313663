<template>
  <div class="account_settings">
    <!-- 居中 -->
    <div class="content">
      <!-- 面包屑导航 start -->
      <bread-crumb :nav_title="nav_title" />
      <!-- 面包屑导航 end -->

      <div class="content_box">
        <!-- 侧边导航 start -->
        <nav-left :xuanzhong="2" />
        <!-- 侧边导航 end -->

        <!-- 右边内容 -->
        <div class="content_total">
          <div class="nav">
            <div class="information">
              <div class="upload_tx">
                <el-upload
                  class="upload-demo"
                  action="https://mentorapi.hi-daas.com/api/file-upload"
                  :headers="uploadParam"
                  :limit="1"
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :before-upload="handleFile"
                  :on-change="handlePictureCardPreview"
                  :on-success="handleAvatarSuccess"
                  accept="JPG、GIF、PNG、JPEG、BMP"
                >
                  <el-button v-if="is_submit" size="small" type="primary"
                    >选择本地图片1</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    仅支持JPG、GIF、PNG、JPEG、BMP格式，文件小于4M
                  </div>
                </el-upload>
                <!-- 图片暂存区 -->
                <div class="state_tx">
                  <div class="state_picture">
                    <img :src="dialogImageUrl" alt="" />
                  </div>
                </div>
              </div>

              <!-- 提交按钮 -->
              <div class="btn" v-if="is_submit" @click="updateInfo">
                <span>提交</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "./components/breadcrumb";
import NavLeft from "./components/NavLeft";
import { getInfo, updateInfo} from "@/api/info.js";

export default {
  data() {
    return {
      nav_title: [{ name: "账户设置" }, { name: "修改头像" }],
      dialogImageUrl: "",
      is_submit: true,
      uploadParam: {},
      test: "",
      // action: "https://mentorapi.hi-daas.com/api/file-upload-fj",
      imageUrl: "",
    };
  },
  components: {
    breadCrumb: breadCrumb,
    NavLeft,
  },
  methods: {
    handlePictureCardPreview(file) {
      console.log("pre");
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (event) {
        console.log(event);
        let img_base64 = this.result;
        self.dialogImageUrl = img_base64;
      };
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一张漂亮的照片哟`);
    },
    // 上传头像验证
    handleFile(file) {
      const isLt2M = file.size / 1024 / 1024 / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 4MB!");
        return;
      }
    },
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.test = res.data;
    },
    // 获取用户信息
    getInfo() {
      // 获取Tonken
      let userInfo = this.$cookie.get("user_types");

      if (userInfo === "ccuser") {
        this.is_submit = false;
      }
      // 头像存储
      let token = this.$cookie.get("token");
      getInfo({ token }).then((res) => {
        // console.log(res);
        this.dialogImageUrl = res.data.head_link;
        console.log("1101110", this.dialogImageUrl);
      });
    },
    //修改头像
    updateInfo() {

      if( this.test == ""){

        this.$message("上传不能为空");

      }
        console.log("10", this.test);

      updateInfo({ head_link: this.test }).then((res) => {

        if (res.code == "200") {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          
        }
      });
    },
  },

  mounted() {
    this.getInfo();
    this.uploadParam.Authorization = `Bearer ${this.$cookie.get("token")}`;
    console.log(11, this.$cookie.get("token"));
  },
};
</script>

<style lang="scss" scoped>
// 测试上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 测试上传
.account_settings {
  background: #f5f5f5;
  padding-bottom: 75px;

  .content {
    width: 1200px;
    margin: 0 auto;
    .content_box {
      display: flex;
    }
    .content_total {
      display: flex;
      .nav {
        width: 990px;
        height: 735px;
        background: #ffffff;
        border-radius: 8px;
        margin-left: 10px;
        .information {
          width: 990px;
          height: 325px;
          background: url(./images/message.png) no-repeat center;
          // 本地图片按钮
          .upload_tx {
            padding: 125px 0 0 95px;
            /deep/.el-button {
              width: 160px;
              height: 38px;
              border-radius: 4px;
              border: 1px solid #cccccc;
            }
            /deep/.el-button--primary {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              background: #ffffff;
            }
            /deep/ .el-upload__tip {
              font-size: 14px;
              margin-top: 15px;
            }
            // 头像暂存
            .state_tx {
              width: 550px;
              height: 320px;
              background: #f9f9f9;
              border: 1px solid #ededed;
              margin: 55px 0 58px 0;
              .state_picture {
                width: 150px;
                height: 150px;
                background: #ededed;
                margin: 85px 200px;
                img {
                  width: 150px;
                  // height: 137px;
                  // margin-top: 13px;
                }
              }
            }
          }
          // 提交
          .btn {
            width: 350px;
            height: 50px;
            background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
            border-radius: 4px;
            text-align: center;
            line-height: 50px;
            margin-left: 320px;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 500;
              color: #935d14;
            }
          }
        }
      }
    }
  }
}
</style>
