import request from '@/utils/axios'

// 列表条件
export function getInfo (params) {
  return request({
    url: '/api/auth/user-infor',
    method: 'get',
    params
  })
}

// 用户信息修改
export function updateInfo (data) {
  return request({
    url: '/api/auth/modify-user',
    method: 'post',
    data
  })
}
